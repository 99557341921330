@import url('https://fonts.lehtodigital.fi/css?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Ubuntu+Mono:wght@400;700&display=swap');

:root {
    --bs-warning-rgb: 176, 110, 4;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 11pt;
    font-weight: 300;
    background: rgb(35,102,65);
    background: linear-gradient(11deg, rgba(35,102,65,1) 1%, rgba(140,246,178,1) 100%); 
    background-attachment: fixed;
    background-size: 100%;
}

strong, b {
    font-weight: 700;
}

tt, pre, code, kbd, .code, .tt {
    font-family: 'Ubuntu Mono', monospace;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

#app {
    margin-bottom: 150px;
}

.app-container {
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: 4px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.app-title {
    font-family: 'Ubuntu Mono', monospace;
    font-weight: 700;
}

.app-file-drop {
    background-color: rgba(0, 0, 0, 0.02);
    border: 2px dashed rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    text-align: center;
    padding: 15px;
    font-size: 14pt;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.app-file-drop.hovering {
    border: 2px solid deepskyblue;
    box-shadow: 0 0 15px deepskyblue;
    color: deepskyblue;
}

.app-file-drop small {
    font-style: italic;
    font-weight: 300;
    font-size: 11pt;
}

.feather {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    vertical-align: middle;
    margin-top: -0.15em;
    color: inherit;
}

.app-log {
    height: 250px;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    padding: 10px;
}