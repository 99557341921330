@import "https://fonts.lehtodigital.fi/css?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Ubuntu+Mono:wght@400;700&display=swap";
:root {
  --bs-warning-rgb: 176, 110, 4;
}

body {
  background: linear-gradient(11deg, #236641 1%, #8cf6b2 100%) 0 0 / 100% fixed;
  font-family: Open Sans, sans-serif;
  font-size: 11pt;
  font-weight: 300;
}

strong, b {
  font-weight: 700;
}

tt, pre, code, kbd, .code, .tt {
  font-family: Ubuntu Mono, monospace;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

#app {
  margin-bottom: 150px;
}

.app-container {
  background-color: #ffffffe6;
  border-bottom: 4px solid #00000026;
  border-radius: 4px;
}

.app-title {
  font-family: Ubuntu Mono, monospace;
  font-weight: 700;
}

.app-file-drop {
  text-align: center;
  color: #00000080;
  cursor: pointer;
  background-color: #00000005;
  border: 2px dashed #0000001a;
  border-radius: 15px;
  padding: 15px;
  font-size: 14pt;
  font-weight: 700;
}

.app-file-drop.hovering {
  color: #00bfff;
  border: 2px solid #00bfff;
  box-shadow: 0 0 15px #00bfff;
}

.app-file-drop small {
  font-size: 11pt;
  font-style: italic;
  font-weight: 300;
}

.feather {
  width: 1.2em;
  height: 1.2em;
  vertical-align: middle;
  color: inherit;
  margin-top: -.15em;
  display: inline-block;
}

.app-log {
  height: 250px;
  background-color: #00000005;
  border-radius: 5px;
  padding: 10px;
  overflow-y: scroll;
}

/*# sourceMappingURL=index.79f59ce8.css.map */
